import React, { useState, forwardRef } from "react";
import { motion } from "framer-motion";
import "./FAQSection.css";

const FAQSection = forwardRef((props, ref) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is ResearchPro?",
      answer:
        "ResearchPro provides research workshops that specifically help students in getting to the top of academic research. We provide total research support from writing a proposal for research to data analysis so that you end up having insights into practical research at all levels.",
    },
    {
      question: "Who are the workshops for?",
      answer:
        "Our workshops are ideal for Master's and PhD students who are conducting research or submitting a research paper. New researchers interested in professional development in academic skills. Researchers interested in advanced methodologies and data analysis tools.",
    },
    {
      question: "Are the workshops online or on campus?",
      answer:
        "All our research workshops are currently conducted online. This will help you participate from any corner of the world.",
    },
    {
      question: "How do I register for a workshop?",
      answer:
        "To register, just head on to our Events page to check available sessions and sign up directly. Early bird discounts may apply, so make sure to register early to take advantage of special pricing!",
    },
    {
      question: "Will I get a certificate of participation?",
      answer:
        "Yes, after completing any workshop, participants will be issued an e-certificate for every course they completed.",
    },
    {
      question: "How can I contact ResearchPro to get more details?",
      answer:
        "When you have further queries or need additional information, please contact us at the Contact Us page. We will be happy to assist you with all your questions regarding our workshops and/or services.",
    },
  ];

  return (
    <motion.div
      ref={ref}
      variants={props.variants}
      initial={props.initial}
      animate={props.animate}
      whileInView={props.whileInView}
      transition={props.transition}
      viewport={props.viewport}
      className="faq-section"
    >
      <h2 className="faq-header">Frequently Asked Questions</h2>
      <div className="faq-items">
        {faqs.map((faq, index) => (
          <motion.div
            key={index}
            className={`faq-item ${activeIndex === index ? "active" : ""}`}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div
              className="faq-question"
              onClick={() => toggleFAQ(index)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => e.key === "Enter" && toggleFAQ(index)}
            >
              <h4>{faq.question}</h4>
              <span>{activeIndex === index ? "-" : "+"}</span>
            </div>
            {activeIndex === index && (
              <motion.div
                className="faq-answer"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <p>{faq.answer}</p>
              </motion.div>
            )}
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
});

export default FAQSection;
