import React from "react";
import "./Navbar.css";
import Research_Pro_Logo from "../assets/Research_Pro_Logo_2.webp";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <>
      <nav className="navbar">
        <img
          className="logoImage"
          src={Research_Pro_Logo}
          alt="Logo"
          onClick={() => navigate("/")}
        />
        <div className="navLinks">
          <a href="/">Home</a>
          <a href="/events">Events</a>
          <a href="/contactus">Contact Us</a>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
