import React, { useEffect, useState } from "react";
import "./overlay.css";
import "./form.css";
import "./UpcomingEvent.css";
import ProposalTestimonial from "./ProposalTestimonial";
import { MdOutlineOndemandVideo } from "react-icons/md";
import Research_Pro_Logo from "../assets/Research_Pro_Logo_2.webp";
import ResearchProposal from "../assets/ResearchProposal.webp";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import countryList from "react-select-country-list"; // Country list
import Select from "react-select";

const UpcomingEvent = () => {
  const [latestEvent, setLatestEvent] = React.useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [isPayPalVisible, setIsPayPalVisible] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [minDate, setMinDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null); // State for selected country


  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const formattedDate = tomorrow.toISOString().split("T")[0];
    setMinDate(formattedDate);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(latestEvent);
    const eventId = latestEvent?._id; // Renamed to match backend
    const workshopId = "Research Proposals"; // Static workshopId
    const country = selectedCountry?.label || "Not specified"; // Get the selected country


    try {
      const response = await axios.post(
        "https://researchpro.online/formSubmit",
        { name, email, date, eventId, workshopId, country } // Sending as eventId
      );
      if (response.status === 200) {
        localStorage.setItem("email", email);
        setIsPayPalVisible(true);
        setIsFormSubmitted(true);
      }
      console.log(name, email);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const location = useLocation();

  useEffect(() => {
    async function fetchLatestEvent() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/get-latest-event`
        );
        const event = response.data;
        setLatestEvent(event);
        // Convert Buffer to Blob and then to Object URL
      } catch (error) {
        console.error("Error fetching latest event:", error);
      }
    }
    fetchLatestEvent();
    if (location.pathname === "/upcoming") {
      document.body.style.backgroundColor = "#ffffff";
    } else {
      // Reset to default or another color if needed
      document.body.style.backgroundColor = "#d7c6f3";
    }

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [location.pathname]);

  // const convertBufferToBlobUrl = (buffer, contentType) => {
  //   // console.log("Buffer:", buffer);
  //   // console.log("Content Type:", contentType);
  //   if (buffer && contentType) {
  //     const byteArray = new Uint8Array(buffer.data);
  //     const blob = new Blob([byteArray], { type: contentType });
  //     const blobUrl = URL.createObjectURL(blob);
  //     // console.log("Generated Blob URL:", blobUrl);
  //     return blobUrl;
  //   }
  //   return null;
  // };

  // const imageSrc = useMemo(() => {
  //   return convertBufferToBlobUrl(
  //     latestEvent.image?.data,
  //     latestEvent.image?.contentType
  //   );
  // }, [latestEvent.image?.data, latestEvent.image?.contentType]);

  useEffect(() => {
    if (isPayPalVisible) {
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=BAAYWXYvMr4CcyPUugFMlYcVLnq3IJBmWAmkw2ExaA4hGWM83mOa4sfikVNFUT8HkyjKKC22ihzRZoLWSw&components=hosted-buttons&disable-funding=venmo&currency=USD";
      script.addEventListener("load", () => {
        window.paypal
          .HostedButtons({
            hostedButtonId: "GA5HXEM6PM67J",
          })
          .render("#paypal-container-GA5HXEM6PM67J");
      });
      document.body.appendChild(script);
    }
  }, [isPayPalVisible]);

  return (
    <>
      {latestEvent && (
        <div className="upcoming-event-container">
          {/* Meta tags */}
          <Helmet>
            <title>Research Proposal & AI Workshop | ResearchPro</title>
            <meta
              name="description"
              content="Join ResearchPro's specialized workshops on research proposal writing and AI techniques. Enhance your skills for academic and research success."
            />
          </Helmet>

          <div className="header">
            {!ResearchProposal ? (
              <div className="skeleton-loader">Loading...</div>
            ) : (
              <img loading="lazy" src={ResearchProposal} alt="EventImage" />
            )}
          </div>

          <div className="event-details">
            <div className="event-details-header">
              <div className="left-half-event-details">
                <h2 className="page-title">
                  Unlock the Secrets to Designing Winning Proposals with AI:
                  A2-Day Workshop
                </h2>
                <div className="page-subtitle">
                  <strong>
                    Are you interested in gaining skills to write winning
                    proposals and wielding the power of AI in crafting impactful
                    researchproposals? Then, join our special 2-day
                    workshop,where you will learn the art of proposal design and
                    gain hands-on experience with the latest cutting-edge tools
                    driven by AI!
                  </strong>
                </div>
              </div>
              <div className="right-half-event-details">
                <div className="payment-info">
                  <div className="box larger-than-800">
                    <div>
                      {" "}
                      <span className="original-price">$99.99</span>{" "}
                      <span className="discounted-price">
                        Early Bird Price: $25
                      </span>{" "}
                    </div>
                    <a className="button" href="#popup1">
                      <button>Register Now</button>
                    </a>
                  </div>
                </div>
              </div>

              {/* Second box for screen sizes less than 800px  */}

              <div className="box smaller-than-800">
                <div>
                  {" "}
                  <span className="original-price">$99.99</span>{" "}
                  <span className="discounted-price">
                    Early Bird Price: $25
                  </span>{" "}
                </div>
                <a className="button" href="#popup1">
                  <button>Register Now</button>
                </a>
              </div>

              <div id="popup1" className="overlay">
                <div className="popup">
                  <h2>Register Now</h2>
                  <a className="close" href="#a">
                    ×
                  </a>
                  <div className="content">
                    {!isFormSubmitted && (
                      <form
                        name="contact"
                        method="POST"
                        netlify
                        data-netlify="true"
                        onSubmit={(event) => handleSubmit(event)}
                      >
                        <input type="hidden" name="form-name" value="contact" />
                        <input type="hidden" name="workshopId" value="Research Purposal" />
                        <label htmlFor="name">Name:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          required
                        />

                        <label htmlFor="date">Choose Preferred Date:</label>
                        <input
                          type="date"
                          id="date"
                          name="date"
                          value={date}
                          min={minDate}
                          onChange={(e) => setDate(e.target.value)}
                          required
                        />

                        <label htmlFor="email">Email:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                         <label htmlFor="country">Country:</label>
                        <Select
                          options={countryList().getData()} // Get country list from library
                          value={selectedCountry}
                          onChange={setSelectedCountry}
                          className="country-select"
                          required
                        />
                        <button type="submit">Continue Checkout</button>
                      </form>
                    )}

                    {isPayPalVisible && (
                      <div
                        id="paypal-container-GA5HXEM6PM67J"
                        style={{ display: "block" }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <h2>Date and Time</h2>
            <div className="date-info">
              <i className="logo">
                <RiCalendarScheduleLine size={20} />
              </i>
              <p className="date_info_full">
                <p className="datep">Flexible Date And Time</p>
                <span>
                  You can select your preferred date once you click on the "Get
                  Tickets" button.
                </span>
              </p>
            </div> */}

            <div className="key-takeaways">
              <h2> Workshop Highlights:</h2>
              <ol>
                <li>
                  <strong> Dates: </strong>
                  Complete the workshop on flexible dates within the 2-window
                  days.
                </li>
                <li>
                  <strong>Duration:</strong> 2 hours a day; or complete both
                  sessions in one day, if you prefer.
                </li>
                <li>
                  <strong>Format: </strong>Video recordings, practical tasks,
                  and online submissions for easy access.
                </li>
                <li>
                  <strong>Cost: </strong>Early Bird Price: <strong>$25</strong>{" "}
                  (Original Price: <strong>$99.99</strong>)
                </li>
              </ol>
            </div>

            <div className="key-takeaways">
              <h2> What You Will Take Away:</h2>
              <ol>
                <li>
                  <strong> Crafting a Compelling Proposal:</strong> Learn how to
                  craft a compelling abstract, literature review, methodology,
                  and expected outcomes. Structure your proposal for maximum
                  impact and clarity.
                </li>
                <li>
                  <strong>Strategic Topic Selection:</strong> Learn how to
                  select a research topic that is innovative, impactful, and
                  suited to your academic goals and coursework.
                </li>
                <li>
                  <strong>Feasibility of Your Topic:</strong> Learn how to
                  assess the feasibility of your topic by evaluating
                  scope,resources, timeframes, and expertise to avoid
                  overcommitment and frustration.
                </li>
                <li>
                  <strong>Avoiding the Most Common Pitfalls:</strong> Identify
                  common mistakes committed while writing a proposal,and learn
                  how to avoid them for a smoother journey towards success.
                </li>
                <li>
                  <strong>Aligning with Academic Goals:</strong> Learn how to
                  align your proposal with academic standards and ensure that
                  your work will contribute significantly to your field.
                </li>
              </ol>
            </div>

            <div className="key-takeaways">
              <h2> Interactive Learning Experience:</h2>
              <ol>
                <li>
                  <strong>Hands-On Practice:</strong> Apply real-world tasks
                  using AI tools enhanced for your purposes in the proposal
                  writing process.
                </li>
                <li>
                  <strong>Flexible Learning:</strong> Recorded sessions
                  available at anytime,and easy online submission form for your
                  tasks.
                </li>
                <li>
                  <strong>Certification:</strong> Receive a certificate of
                  completion upon successful task submission, adding value to
                  your academic and professional portfolio.
                </li>
              </ol>
            </div>

            <div className="key-takeaways">
              <h2> How It Works:</h2>
              <ol>
                <li>
                  <strong>Enroll Now:</strong> Secure your spot for just $25.
                </li>
                <li>
                  <strong>Get Started:</strong> After registration, receive
                  workshop materials and recorded session access via email.
                </li>
                <li>
                  <strong>Complete at Your Pace: </strong>
                  Spend 2 hours each day on content, or finish it all in one
                  day. Complete your tasks and submit them online.
                </li>
                <li>
                  <strong>Earn Your Certificate: </strong>
                  Complete your assignments and earn a certificate that
                  demonstrates your enhanced proposal writing skills.
                </li>
              </ol>
            </div>

            <h2> Ready to Dominate Proposal Writing Using AI?</h2>
            <p className="web-certificate">
              This is your opportunity to elevate your proposal-writing skills
              and embrace AI as the game-changer in your work. Don’t wait any
              longer—register early as spots are limited.
            </p>

            <h2>Location</h2>
            <div className="location">
              <div className="logo">
                <i className="icon">
                  <MdOutlineOndemandVideo size={30} />
                </i>
              </div>
              <div className="location_text_info">
                <div>Online </div>
                <div className="text">
                  View workshop resources and recordings from anywhere. A link
                  will be sent to you an hour prior to the workshop.
                </div>
              </div>
            </div>

            <h2>Order Additional Resources:</h2>
            <p className="web-certificate">
              Once you have registered, all the details, including the recorded
              sessions, will be emailed to you, along with instructions for
              completing the tasks. We will walk with you every step of the way.
            </p>

            <h2> Transform Your Proposal Writing Skills Today!</h2>
            <p className="web-certificate last-p">
              Join our flexible and innovative <strong>2-day workshop</strong>{" "}
              and become a master of proposal design with AI!
            </p>
            <br />
            <ProposalTestimonial />
            <hr />
            <h3>Organised By</h3>
            <div className="organised-by">
              <div className="organised-header">
                <div className="left-half-event-details">
                  <img
                    src={Research_Pro_Logo}
                    alt="Location Icon"
                    className="rounded-image"
                    style={{
                      verticalAlign: "middle",
                      marginRight: "10px",
                      height: "60px",
                    }}
                  />
                  <span>Research Pro</span>
                </div>
                <div className="right-half-event-details">
                  <div className="contact-info">
                    <a
                      href="mailto:youremail@example.com"
                      className="contact-button"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              <h3 className="about-orgainser">About Organisers</h3>
              Research Pro is the ultimate platform for mastering research
              skills. With over 7,000 scholars trained, our platform offers
              expert-led webinars on proposal writing, research paper crafting,
              data analysis, and more. Elevate your research skills with proven
              professionalism and practical insights. Join Research Pro and
              unlock your full potential today!
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpcomingEvent;
