import React, { useState } from 'react';
import './loginPage.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const LoginPage = ({ setLogIn }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/log-in-addEvent`, { username, password });
            if (res && res.status === 200) {
                toast.success("You have successfully logged In");
                setLogIn(true);
                // Persist login state
                localStorage.setItem('loggedIn', true);
            } else {
                toast.warning("Invalid Credentials");
            }
        } catch (error) {
            toast.error("Error 500, Failed to reach the server! Try again");
        }

        // Reset fields after login attempt
        setUsername('');
        setPassword('');
    };

    return (
        <>
            <ToastContainer />
            <div className="login-container">
                <form className="login-form" onSubmit={handleLogin}>
                    <h2>Login</h2>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Login</button>
                </form>
            </div>
        </>
    );
};

export default LoginPage;
