import React from 'react';
import './ThankyouPage.css';
import { useNavigate } from 'react-router-dom';
import { useState , useEffect } from 'react';
import axios from 'axios';

const ThankyouPage = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true); // Checkbox initially checked
  const [email, setEmail] = useState(null);

   // Fetch the user's email from localStorage on component mount
   useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleGoBack = () => {
    navigate('/'); // Redirect to the homepage or any other page
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    updateConsent(e.target.checked);
  };

    // Function to update the consent in the backend
    const updateConsent = async (consent) => {
      if (email) {
        try {
          const response = await axios.post('http://localhost:4000/api/consent', {
            email: email,
            consent: consent,
          });
          alert(response.data.message); // Log success message
        } catch (error) {
          console.error('Error updating consent:', error);
        }
      }
    };

    return (
      <div className="thank-you-container">
        <div className="thank-you-message">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="94"
            height="94"
            viewBox="0 0 24 24"
            fill="none"
            className="checkmark-icon"
          >
            <circle cx="12" cy="12" r="12" fill="#4CAF50" />
            <path d="M9 12l2 2l4-4" stroke="#ffffff" strokeWidth="2.5" fill="none" />
          </svg>
          
          <h1>Thank You!</h1>
          <p>Thank you for joining us! Your payment was successful, and you’re now all set for the upcoming webinar.</p>
          <p>The access link will be sent to your email 24 hours before the webinar. Make sure to keep an eye on your inbox!</p>
        </div>
        <button className="back-home-button" onClick={handleGoBack}>
          Back to Home
        </button>
        <div className="checkbox-container">
            <input 
              type="checkbox" 
              id="emailConsent" 
              checked={isChecked} 
              onChange={handleCheckboxChange} 
            />
            <label htmlFor="emailConsent">
              We will store your email for future communications. Uncheck if you do not want this.
            </label>
          </div>
      </div>
    );
  };
  
  export default ThankyouPage;