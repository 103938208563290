import React from "react";
import "./Event.css";
import ProposalImg from "../assets/event-proposal.webp";
import AnalysisImg from "../assets/event-analysis.webp";
import { Helmet } from "react-helmet-async";

const Event = () => {
  return (
    <div className="event-container">
      {/* Meta tags */}
      <Helmet>
        <title>
          ResearchPro Events: Workshops on Proposals & Data Analysis
        </title>
        <meta
          name="description"
          content="Explore ResearchPro events! Join our Research Proposal Workshop and Data Analysis sessions to master essential academic and research skills.
"
        />
      </Helmet>

      <div className="event-box1">
        <div className="content-div">
          <h3>Data Analysis Workshop</h3>
          <p>
            Elevate your data analysis skills in this intensive 2-hour online
            session. Learn to identify the right analytical techniques for your
            study, understand methods like regression and ANOVA, and apply them
            to real-world datasets. Designed for early-career researchers and
            students, this workshop emphasizes practical insights, hands-on
            application, and effective interpretation of results. Gain the
            confidence to analyze your data and draw meaningful conclusions,
            ensuring your research stands out.
          </p>
        </div>
        <div className="img-div">
          <img src={AnalysisImg} alt="Event" />
          <a href="/analysis">Data Analysis</a>
        </div>
      </div>
      <div className="event-box1">
        <div className="content-div">
          <h3>Research Proposal Writing Workshop</h3>
          <p>
            A 2-day online workshop focused on effectively writing a research
            proposal. This workshop is tailored for Master’s and PhD students,
            covering essential elements such as selecting a compelling research
            topic, crafting impactful abstracts, structuring methodologies, and
            defining intended outcomes. Participants will get hands-on
            experience with cutting-edge AI tools to refine their writing and
            align it with academic standards. With flexible learning options and
            expert guidance, this workshop is designed to transform your
            proposal-writing skills.
          </p>
        </div>
        <div className="img-div">
          <img src={ProposalImg} alt="Event" />
          <a href="/upcoming">Research Proposal</a>
        </div>
      </div>
    </div>
  );
};

export default Event;
