import React, { useEffect, useState } from "react";
import "./overlay.css";
import "./form.css";
import "./Analysis.css";
import AnalysisTestimonial from "./AnalysisTestimonial.js";
import Research_Pro_Logo from "../assets/Research_Pro_Logo_2.webp";
import DataBanner from "../assets/data-banner.png";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import countryList from "react-select-country-list"; // Country list
import Select from "react-select";

const AnalysisEvent = () => {
  const [latestEvent, setLatestEvent] = React.useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isPayPalVisible, setIsPayPalVisible] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [minDate, setMinDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null); // State for selected country

  useEffect(() => {
    const minSelectableDate = new Date("2024-11-23");
    const formattedDate = minSelectableDate.toISOString().split("T")[0];
    setMinDate(formattedDate);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(latestEvent);
    const eventId = latestEvent?._id; // Renamed to match backend
    const workshopId = "Data Analysis"; // Static workshopId
    const country = selectedCountry?.label || "Not specified"; // Get the selected country

    try {
      const response = await axios.post(
        "https://researchpro.online/formSubmit",
        { name, email, eventId, workshopId, country}
      );
      if (response.status === 200) {
        localStorage.setItem("email", email);
        setIsPayPalVisible(true);
        setIsFormSubmitted(true);
      }
      console.log(name, email);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const location = useLocation();

  useEffect(() => {
    async function fetchLatestEvent() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/get-latest-event`
        );
        const event = response.data;
        setLatestEvent(event);
        // Convert Buffer to Blob and then to Object URL
      } catch (error) {
        console.error("Error fetching latest event:", error);
      }
    }
    fetchLatestEvent();
    if (location.pathname === "/analysis") {
      document.body.style.backgroundColor = "#ffffff";
    } else {
      // Reset to default or another color if needed
      document.body.style.backgroundColor = "#d7c6f3";
    }

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [location.pathname]);

  // const convertBufferToBlobUrl = (buffer, contentType) => {
  //   // console.log("Buffer:", buffer);
  //   // console.log("Content Type:", contentType);
  //   if (buffer && contentType) {
  //     const byteArray = new Uint8Array(buffer.data);
  //     const blob = new Blob([byteArray], { type: contentType });
  //     const blobUrl = URL.createObjectURL(blob);
  //     // console.log("Generated Blob URL:", blobUrl);
  //     return blobUrl;
  //   }
  //   return null;
  // };

  // const imageSrc = useMemo(() => {
  //   return convertBufferToBlobUrl(
  //     latestEvent.image?.data,
  //     latestEvent.image?.contentType
  //   );
  // }, [latestEvent.image?.data, latestEvent.image?.contentType]);

  useEffect(() => {
    if (isPayPalVisible) {
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=BAAYWXYvMr4CcyPUugFMlYcVLnq3IJBmWAmkw2ExaA4hGWM83mOa4sfikVNFUT8HkyjKKC22ihzRZoLWSw&components=hosted-buttons&disable-funding=venmo&currency=USD";
      script.addEventListener("load", () => {
        window.paypal
          .HostedButtons({
            hostedButtonId: "B7K5Q2SQYP398",
          })
          .render("#paypal-container-B7K5Q2SQYP398");
      });
      document.body.appendChild(script);
    }
  }, [isPayPalVisible]);

  return (
    <>
      {latestEvent && (
        <div className="upcoming-event-container">
          {/* Meta tags */}
          <Helmet>
            <title>
              Master Data Analysis: Workshop for Researchers | ResearchPro{" "}
            </title>
            <meta
              name="description"
              content="Master data analysis techniques with ResearchPro's expert-led workshop. Enhance your research skills with practical tools and advanced methodologies."
            />
          </Helmet>

          <div className="header">
            {!DataBanner ? (
              <div className="skeleton-loader">Loading...</div>
            ) : (
              <img loading="lazy" src={DataBanner} alt="DataImage" />
            )}
          </div>

          <div className="event-details">
            <div className="event-details-header">
              <div className="left-half-event-details">
                <h2 className="page-title">
                  Unlock the Power of Data Analysis for Your Research
                </h2>
                <div className="page-subtitle">
                  Are you a researcher with a solid background in data analysis
                  but still facing challenges like:
                  <ul>
                    <li>Choosing the best method to apply to your study?</li>
                    <li> Understanding which techniques are applicable?</li>
                    <li>
                      Effectively presenting and interpreting your results?
                    </li>
                  </ul>
                  If you’ve answered yes to any of these questions, then our
                  Data Analysis Workshop is exactly what you need!
                </div>
              </div>
              <div className="right-half-event-details">
                <div className="payment-info">
                  <div className="box larger-than-800">
                    <p>
                      <strong>Early Bird:</strong> <strong>$45</strong> (Before
                      Dec 20),
                    </p>
                    <p>
                      <strong>$125</strong> (After Dec 20).
                    </p>
                    <a className="button" href="#popup1">
                      <button>Register Now</button>
                    </a>
                  </div>
                </div>
              </div>

              {/* Second box for screen sizes less than 800px  */}

              <div className="box smaller-than-800">
                <div>
                  <strong>Early Bird:</strong> $45 (Before Dec 20), $125 (After
                  Dec 20).
                </div>
                <a className="button" href="#popup1">
                  <button>Register Now</button>
                </a>
              </div>

              <div id="popup1" className="overlay">
                <div className="popup">
                  <h2>Register Now</h2>
                  <a className="close" href="#a">
                    ×
                  </a>
                  <div className="content">
                    {!isFormSubmitted && (
                      <form
                        name="contact"
                        method="POST"
                        netlify
                        data-netlify="true"
                        onSubmit={(event) => handleSubmit(event)}
                      >
                        <input type="hidden" name="form-name" value="contact" />
                        <input type="hidden" name="workshopId" value="Data Analysis" />
                        <label htmlFor="name">Name:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        {/* <label htmlFor="date">Choose Preferred Date:</label>
                        <input
                          type="date"
                          id="date"
                          name="date"
                          value={date}
                          min={minDate}
                          onChange={(e) => setDate(e.target.value)}
                          required
                        /> */}
                        <label htmlFor="email">Email:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                         {/* Country Dropdown */}
                         <label htmlFor="country">Country:</label>
                        <Select
                          options={countryList().getData()} // Get country list from library
                          value={selectedCountry}
                          onChange={setSelectedCountry}
                          className="country-select"
                          required
                        />
                        <button type="submit">Continue Checkout</button>
                      </form>
                    )}

                    {isPayPalVisible && (
                      <div
                        id="paypal-container-B7K5Q2SQYP398"
                        style={{ display: "block" }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <h2>About the Workshop</h2>
            <p className="date_info_full">
              Join us for an intensive 2-hour session designed to take you
              beyond the basics and into the realm of effective data analysis.
              This workshop will equip you with practical insights, guiding you
              through the process of method selection, application, and
              interpretation. We break down complex concepts into
              easy-to-understand, actionable steps, so you can confidently
              analyze your research data like a pro.
            </p>
            <div className="key-takeaways">
              <h2>Workshop Details</h2>
              <ol>
                <li>
                  <strong>Duration:</strong> 2 hours
                </li>
                <li>
                  <strong>Platform: </strong> Online (accessible from anywhere
                  in the world)
                </li>
                <li>
                  <strong>Language:</strong> English
                </li>
                <li>
                  <strong>Date:</strong> 22 December, Limited Slots Available!
                </li>
              </ol>
            </div>
            <div className="key-takeaways">
              <h2>What You'll Take Away</h2>
              <ol>
                <li>
                  <strong>Hands On Application:</strong> Practical ways to solve
                  real world research problems.
                </li>
                <li>
                  <strong>Tools And Techniques:</strong> Understand techniques
                  like
                  <strong>
                    {" "}
                    Regression, ANOVA, Chi-Square Tests, Thematic Analysis,
                  </strong>
                  and more. Learn when and how to apply each technique in your
                  study.
                </li>
                <li>
                  <strong>Upon Completion: </strong> Get Certificate of
                  completion, Free resources including code samples, templates,
                  cheat sheets.
                </li>
                <li>
                  <strong>Results Interpretation: </strong>Translate statistical
                  outputs or qualitative insights into meaningful, actionable
                  conclusions that can positively impact your research.
                </li>
              </ol>
            </div>
            <div className="key-takeaways">
              <h2>This Workshop is Perfect For:</h2>
              <ol>
                <li>
                  <strong>Master's and PhD students</strong> who are conducting
                  research or writing theses and need data analysis skills.
                </li>
                <li>
                  <strong>Early-career researchers </strong> looking to build on
                  their data analysis expertise
                </li>
                <li>
                  <strong>Researchers </strong>who are familiar with data and
                  analytics but need guidance on selecting and applying methods
                  appropriately
                </li>
              </ol>
            </div>
            <div className="key-takeaways">
              <h2> Why This Workshop?</h2>
              <ol>
                <li>
                  <strong>Easy to Understand:</strong> Using simple language
                  combined with real-life examples, even the most complex
                  concepts become easy to grasp.
                </li>
                <li>
                  <strong>Participative Sessions:</strong> Work with real-life
                  datasets and examples reflecting your research, ensuring
                  relevance and engagement.
                </li>
                <li>
                  <strong>Expert Trainers: </strong>Learn from experienced,
                  research-driven instructors who will be with you every step of
                  the way.
                </li>
                <li>
                  <strong>Tangible Takeaways: </strong>Walk away with actionable
                  tips, resources, and strategies that you can apply directly to
                  your research. skills.
                </li>
              </ol>
            </div>
            <h2>Fee Structure</h2>
            <ol>
              <li>
                <strong>Early Bird Price:</strong> : $45 (If Registered Before
                20 December)
              </li>
              <li>
                <strong>Regular Price:</strong> $125 (After 20 December)
              </li>
            </ol>
            <p className="web-certificate last-p">
              Spots are limited to ensure an excellent, interactive experience,
              so don’t miss out on this opportunity to elevate your data
              analysis skills!
            </p>
            <p className="web-certificate last-p">
              Register Now to secure your spot and transform your approach to
              data analysis for better,more impactful research.
            </p>
            <br />
            <AnalysisTestimonial />
            <hr />
            <h3>Organised By</h3>
            <div className="organised-by">
              <div className="organised-header">
                <div className="left-half-event-details">
                  <img
                    src={Research_Pro_Logo}
                    alt="Location Icon"
                    className="rounded-image"
                    style={{
                      verticalAlign: "middle",
                      marginRight: "10px",
                      height: "60px",
                    }}
                  />
                  <span>Research Pro</span>
                </div>
                <div className="right-half-event-details">
                  <div className="contact-info">
                    <a
                      href="mailto:youremail@example.com"
                      className="contact-button"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              <h3 className="about-orgainser">About Organisers</h3>
              ResearchPro is a leading platform dedicated to empowering PhD and
              master’s students in their research journey. With expert-led
              webinars on proposal writing, research papers, data analysis, and
              more, we provide valuable insights and practical tools for
              academic success. Join ResearchPro and unlock your full potential!
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AnalysisEvent;
