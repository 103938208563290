import React from "react";
import "./AnalysisTestimonial.css";
import User1 from "../assets/user1.jpg";
import User2 from "../assets/user2.jpg";
import User3 from "../assets/user3.jpg";

const AnalysisTestimonial = () => {
  return (
    <>
      <div className="testimonial-cont">
        <h1 className="testimonials-header">Testimonials</h1>
        <div className="card-cont">
          <div className="cards">
            <p className="r-text">
              "Going through the Data Analysis workshop gave me a lot of
              confidence in selecting the right techniques for my study.
              Realistic examples made something like regression and ANOVA so
              simple."
            </p>
            <p className="r-name">
              <img src={User1} alt="Profile Picture" />– Maxim, Career
              Researcher, Germany
            </p>
          </div>
          <div className="cards">
            <p className="r-text">
              "I was really struggling to interpret my data before coming to
              this workshop. Today, I feel able to confidently analyze my
              research and report meaningful conclusions in my research paper."
            </p>
            <p className="r-name">
              <img src={User2} alt="Profile Picture" />– Olivia, PhD Candidate,
              USA
            </p>
          </div>
          <div className="cards">
            <p className="r-text">
              "The Data Analysis Workshop was exactly what I needed. It
              clarified methods like regression, ANOVA, and thematic analysis
              step by step. The sessions on datasets were fantastic, and I now
              feel confident in analyzing and presenting results. The instructor
              explained complex topics brilliantly!"
            </p>
            <p className="r-name">
              <img src={User3} alt="Profile Picture" />– Mark Johnson, PhD
              Candidate (UK)
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalysisTestimonial;
