import React from "react";
import "./ProposalTestimonial.css";
import User4 from "../assets/user4.jpg";
import User5 from "../assets/user5.jpg";
import User6 from "../assets/user6.jpg";

const ProposalTestimonial = () => {
  return (
    <>
      <div className="testimonial-cont">
        <h1 className="testimonials-header">Testimonials</h1>
        <div className="card-cont">
          <div className="cards">
            <p className="r-text">
              "The "Research Proposal Writing" workshop helped me structure my
              proposal with clarity and confidence. The step-by-step guidance
              about how to write an impactful abstract and methodology was
              invaluable for my PhD proposal!"
            </p>
            <p className="r-name">
              <img src={User4} alt="Profile Picture" /> – Ethan, PhD Student,
              USA
            </p>
          </div>
          <div className="cards">
            <p className="r-text">
              "This workshop not only polished my writing skills but also
              equipped me with the tools to express my thoughts. I must say it
              was a very practical and informative session, and exactly what I
              needed to get my proposal right."
            </p>
            <p className="r-name">
              <img src={User5} alt="Profile Picture" /> – Charlotte, Master's
              Student, UK
            </p>
          </div>
          <div className="cards">
            <p className="r-text">
              "This workshop transformed my approach to writing research
              proposals. The abstract and methodology tips were invaluable, and
              the AI tools seamlessly improved my writing. The flexible pace was
              a bonus. I’m confident my proposal will stand out—thank you!"
            </p>
            <p className="r-name">
              <img src={User6} alt="Profile Picture" /> – Carlos Ramirez,
              Master's Student (Spain)
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProposalTestimonial;
