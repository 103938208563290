import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./pages/Navbar";
import "./App.css";
import HomePage from "./pages/HomePage";
import UpcomingEvent from "./pages/UpcomingEvent";
import AddEvent from "./pages/AddEvent";
import Footer from "./pages/Footer";
import Analytics from "./analytics/Analytics";
import TrackPageView from "./analytics/TrackPageView";
import ThankyouPage from "./pages/Thankyou/ThankyouPage";
import ContactUs from "./pages/contactus.js";
import Events from "./pages/Event.js";
import Analysis from "./pages/Analysis.js";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <div className="page-container">
          <Analytics />
          <Navbar />
          <Routes>
            <Route path="*" element={<HomePage />} />
            <Route path="/events" element={<Events />} />
            <Route path="/upcoming" element={<UpcomingEvent />} />
            <Route path="/analysis" element={<Analysis />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/addEvent" element={<AddEvent />} />
            <Route path="/thank-you" element={<ThankyouPage />} />
          </Routes>
          <TrackPageView />
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;
